import React, { useState, useMemo } from 'react'

import {
  FuncoesDataHora,
  IconeAvaliacao,
  IconeInformacaoMedio,
  IconeNota,
  TipoMaterialComplementar
} from 'src/componentes'
import { StepperItem } from 'src/componentes/stepper/tipos'
import { Api } from 'src/servicos'
import {
  ModalidadeCurso,
  SalaAulaOutros,
  SalaAulaTipoMaterial,
  OpcaoTCC,
  TipoRecuperacao
} from 'src/tipos'
import { createContainer } from 'unstated-next'
import { v4 } from 'uuid'

import {
  itemStepperMaterial,
  mensagemEtapaBloqueada,
  MinhasNotas
} from '../shared'
import {
  calcularDistanciaData,
  somarDias,
  finalDoDia
} from '../shared/funcoes/calcularDatas'
import { Avaliacao } from './avaliacao'
import { GrupoDiscussao } from './grupo-discussao'
import { GrupoDiscussaoTCC } from './grupo-discussao-tcc'
import { Material } from './material'
import { OpteProrrogacao } from './opte-prorrogacao'
import { OpteTCC } from './opte-tcc'
import { PesquisaSatisfacaoSalaAulaOutros } from './pesquisa-satisfacao'
import { PesquisaSatisfacaoProfessor } from './pesquisa-satisfacao-professor'
import { SobreCurso } from './sobre-curso'
import { SalaAulaOutrosContainer, SalaAulaOutrosContainerEstado } from './tipos'

const useSalaAulaOutros = (initialState: { idMatricula: string }) => {
  const [estado, definirEstado] = useState<SalaAulaOutrosContainerEstado>({
    idMatricula: '',
    ...initialState,
    salaAula: {} as SalaAulaOutros,
    itensMarcados: [],
    recarregarSala: false,
    passoAoAtualizarSala: null,
    fecharModal: false
  })

  const materiaisLidos = (itens: string[]) => {
    return itens.every(i => estado.itensMarcados.includes(i))
  }

  const etapas: StepperItem[] = useMemo(() => {
    const { salaAula } = estado
    const TEMPO_DISPONIBILIDADE_MATERIAIS = 30

    const bloquearAcessoAluno =
      salaAula.moduloTCC &&
      salaAula.dataBloqueioSalaAula &&
      calcularDistanciaData(salaAula.dataBloqueioSalaAula) > 0 &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Sim] &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Nao] &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.NaoAutomatico] &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Cancelamento] &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Dispensa] &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Aproveitamento]

    if (!salaAula.id) return []

    let novoPasso = 1

    const novasEtapas: StepperItem[] = [
      {
        id: 'sobre-curso',
        titulo: 'Comece aqui',
        icone: IconeInformacaoMedio,
        passo: !bloquearAcessoAluno && novoPasso,
        componente: <SobreCurso />,
        bloqueado: bloquearAcessoAluno
      }
    ]

    novoPasso += 1

    novasEtapas.push({
      id: 'minhas-notas',
      titulo:
        estado.salaAula.modalidadeCurso ===
        ModalidadeCurso.PosGraduacaoPresencial
          ? 'Ver Notas e Presenças'
          : 'Minhas Notas',
      icone: IconeNota,
      passo: novoPasso,
      componente: (
        <MinhasNotas
          idMatricula={estado.idMatricula}
          tipoAvaliacao={salaAula.tipoAvaliacao}
          modalidadeCurso={estado.salaAula.modalidadeCurso}
        />
      ),
      bloqueado: bloquearAcessoAluno
    })

    novoPasso += 1

    salaAula.modulos?.forEach(modulo => {
      const disciplinas: StepperItem[] = []

      const dataLiberacaoModulo = new Date(modulo.dataLiberacao)
      const moduloBloqueado =
        modulo.dataLiberacao && dataLiberacaoModulo > new Date()

      const tempoDisponibilidade =
        salaAula?.tempoDisponibilidade ?? TEMPO_DISPONIBILIDADE_MATERIAIS
      const dataFimSalaAulaComTempoDisponibilidade = finalDoDia(
        somarDias(salaAula.dataFimSalaAula, tempoDisponibilidade)
      )

      const passouDataFimSalaAula =
        calcularDistanciaData(salaAula.dataFimSalaAula) > 0
      const passouDataFimSalaAulaComTempoDisponibilidade =
        calcularDistanciaData(dataFimSalaAulaComTempoDisponibilidade) > 0

      const materiaisBloqueados =
        moduloBloqueado || passouDataFimSalaAulaComTempoDisponibilidade

      let mensagemBloqueio

      if (passouDataFimSalaAulaComTempoDisponibilidade) {
        mensagemBloqueio = `Todos os materiais ficam disponíveis por mais ${tempoDisponibilidade}
        dias após a data de finalização curso. Este período encerrou em ${FuncoesDataHora.dataToLocaleString(
          dataFimSalaAulaComTempoDisponibilidade
        )}.`
      }

      if (moduloBloqueado) {
        mensagemBloqueio = mensagemEtapaBloqueada(dataLiberacaoModulo)
      }

      modulo.disciplinas?.forEach(disciplina => {
        const subItens: StepperItem[] = []
        const disciplinaBloqueada = disciplina.aproveitamento

        const materiaisDisciplina = disciplina.materiais.map(x => x.id)
        const pesquisaSatisfacaoBloqueada = !materiaisLidos(materiaisDisciplina)

        disciplina.materiais?.forEach((material, indice) => {
          subItens.push(
            itemStepperMaterial(
              material,
              <Material
                id={material.id}
                tipo={material.tipoMaterial}
                url={
                  material.tipoMaterial === SalaAulaTipoMaterial.Video
                    ? material.linkMaterial
                    : undefined
                }
                ultimoMaterial={indice + 1 === disciplina.materiais.length}
                pdf={material.extensao === '.pdf'}
              />,
              novoPasso,
              materiaisBloqueados,
              mensagemBloqueio
            )
          )

          if (
            (material.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
              material.extensao === '.pdf') ||
            material.tipoMaterial === SalaAulaTipoMaterial.Video ||
            material.tipoMaterial === SalaAulaTipoMaterial.HiperLink
          ) {
            novoPasso += 1
          }
        })

        if (disciplina.exibirPesquisaSatisfacao) {
          subItens.push({
            id: `${modulo.id}-${disciplina.id}-${disciplina.pesquisaSatisfacaoId}`,
            titulo: 'Pesquisa de Satisfação',
            passo: novoPasso,
            componente: (
              <PesquisaSatisfacaoProfessor
                key={`${modulo.id}-${disciplina.id}-${disciplina.pesquisaSatisfacaoId}`}
                disciplinaId={disciplina.id}
                pesquisaSatisfacaoId={disciplina.pesquisaSatisfacaoId}
                moduloId={modulo.id}
              />
            ),
            bloqueado: pesquisaSatisfacaoBloqueada,
            mensagemBloqueado:
              'Para responder a pesquisa é necessário assistir todas as aulas da disciplina.'
          })

          novoPasso += 1
        }

        if (disciplina.avaliacaoId) {
          const dataLiberacaoAvaliacao = new Date(
            disciplina.dataLiberacaoAvaliacao
          )
          const dataLimiteAvaliacao = new Date(disciplina.dataLimiteAvaliacao)

          const avaliacaoBloqueadaAntesPrazo =
            disciplina.dataLiberacaoAvaliacao &&
            dataLiberacaoAvaliacao > new Date()
          const avaliacaoBloqueadaDepoisPrazo =
            disciplina.dataLimiteAvaliacao && dataLimiteAvaliacao <= new Date()

          const avaliacaoBloqueadaPorAproveitamentoReingressso =
            salaAula.reingresso && disciplina.reingresso

          const avaliacaoBloqueadaRecuperacao =
            salaAula.periodoRecuperacao &&
            salaAula.tipoRecuperacao === TipoRecuperacao.Disciplina &&
            !disciplina.recuperacao

          let mensagemAvaliacaoBloqueada: string

          if (moduloBloqueado) {
            mensagemAvaliacaoBloqueada = mensagemEtapaBloqueada(
              dataLiberacaoModulo
            )
          }

          if (salaAula.reingresso && disciplina.reingresso) {
            mensagemAvaliacaoBloqueada =
              'Avaliação aproveitada por conta do reingresso'
          }

          if (avaliacaoBloqueadaAntesPrazo) {
            mensagemAvaliacaoBloqueada = 'Avaliação ainda não está liberada'
          }

          if (avaliacaoBloqueadaDepoisPrazo) {
            mensagemAvaliacaoBloqueada =
              'Prazo limite de envio da avaliação encerrado'
          }

          if (passouDataFimSalaAula) {
            mensagemAvaliacaoBloqueada = `Seu prazo para realizar esta atividade terminou em
            ${FuncoesDataHora.dataToLocaleString(salaAula.dataFimSalaAula)}.`
          }

          if (disciplina.avaliacaoBloqueadaPesquisaSatisfacaoPendente) {
            mensagemAvaliacaoBloqueada =
              'É preciso responder a pesquisa de satisfação da disciplina para liberar a avaliação'
          }

          if (avaliacaoBloqueadaRecuperacao) {
            mensagemAvaliacaoBloqueada = 'Avaliação concluída no tempo regular'
          }

          subItens.push({
            id: disciplina.avaliacaoId,
            titulo: 'Avaliação da Disciplina',
            passo: novoPasso,
            icone: IconeAvaliacao,
            componente: (
              <Avaliacao
                idModulo={modulo.id}
                idDisciplina={disciplina.id}
                idAvaliacao={disciplina.avaliacaoId}
              />
            ),
            bloqueado:
              avaliacaoBloqueadaPorAproveitamentoReingressso ||
              avaliacaoBloqueadaAntesPrazo ||
              avaliacaoBloqueadaDepoisPrazo ||
              moduloBloqueado ||
              passouDataFimSalaAula ||
              avaliacaoBloqueadaRecuperacao ||
              disciplina.avaliacaoBloqueadaPesquisaSatisfacaoPendente,
            mensagemBloqueado: mensagemAvaliacaoBloqueada
          })

          novoPasso += 1
        }

        const mensagemBloqueioDisciplina = disciplina.bloqueadoPesquisaSatisfacaoPendente
          ? 'É preciso realizar a pesquisa de satisfação dos módulos anteriores para liberar a próxima etapa'
          : 'Aprovado por aproveitamento.'

        disciplinas.push({
          id: disciplina.id,
          titulo: disciplina.nomeDisciplina,
          subItens,
          bloqueado:
            disciplinaBloqueada ||
            disciplina.bloqueadoPesquisaSatisfacaoPendente,
          mensagemBloqueado: mensagemBloqueioDisciplina
        })
      })

      if (modulo.avaliacaoId) {
        const dataLiberacaoAvaliacao = new Date(modulo.dataLiberacaoAvaliacao)
        const dataLimiteAvaliacao = new Date(modulo.dataLimiteAvaliacao)

        const avaliacaoBloqueadaAntesPrazo =
          modulo.dataLiberacaoAvaliacao && dataLiberacaoAvaliacao > new Date()

        const avaliacaoBloqueadaDepoisPrazo =
          modulo.dataLimiteAvaliacao && dataLimiteAvaliacao <= new Date()

        const avaliacaoBloqueadaPorAproveitamentoReingressso =
          salaAula.reingresso && modulo.reingresso

        const avaliacaoBloqueadaRecuperacao =
          salaAula.periodoRecuperacao &&
          salaAula.tipoRecuperacao === TipoRecuperacao.Modulo &&
          !modulo.recuperacao

        let mensagemAvaliacaoBloqueada: string

        if (moduloBloqueado) {
          mensagemAvaliacaoBloqueada = mensagemEtapaBloqueada(
            dataLiberacaoModulo
          )
        }

        if (salaAula.reingresso && modulo.reingresso) {
          mensagemAvaliacaoBloqueada =
            'Avaliação aproveitada por conta do reingresso'
        }

        if (avaliacaoBloqueadaAntesPrazo) {
          mensagemAvaliacaoBloqueada = 'Avaliação ainda não está liberada'
        }

        if (avaliacaoBloqueadaDepoisPrazo) {
          mensagemAvaliacaoBloqueada =
            'Prazo limite de envio da avaliação encerrado'
        }

        if (passouDataFimSalaAula) {
          mensagemAvaliacaoBloqueada = `Seu prazo para realizar esta atividade terminou em
          ${FuncoesDataHora.dataToLocaleString(salaAula.dataFimSalaAula)}.`
        }

        if (modulo.avaliacaoBloqueadaPesquisaSatisfacaoPendente) {
          mensagemAvaliacaoBloqueada =
            'É preciso responder a pesquisa de satisfação das disciplinas para liberar a avaliação'
        }

        if (avaliacaoBloqueadaRecuperacao) {
          mensagemAvaliacaoBloqueada = 'Avaliação concluída no tempo regular'
        }

        disciplinas.push({
          id: modulo.avaliacaoId,
          titulo: 'Avaliação do Módulo',
          passo: novoPasso,
          icone: IconeAvaliacao,
          componente: (
            <Avaliacao idModulo={modulo.id} idAvaliacao={modulo.avaliacaoId} />
          ),
          bloqueado:
            avaliacaoBloqueadaPorAproveitamentoReingressso ||
            avaliacaoBloqueadaAntesPrazo ||
            avaliacaoBloqueadaDepoisPrazo ||
            moduloBloqueado ||
            passouDataFimSalaAula ||
            avaliacaoBloqueadaRecuperacao ||
            modulo.avaliacaoBloqueadaPesquisaSatisfacaoPendente,
          mensagemBloqueado: mensagemAvaliacaoBloqueada
        })

        novoPasso += 1
      }

      if (modulo.exibirPesquisaSatisfacao) {
        disciplinas.push({
          id: `${modulo.id}-${modulo.pesquisaSatisfacaoId}`,
          titulo: 'Pesquisa de Satisfação',
          passo: novoPasso,
          componente: (
            <PesquisaSatisfacaoSalaAulaOutros
              pesquisaSatisfacaoId={modulo.pesquisaSatisfacaoId}
              moduloId={modulo.id}
            />
          ),
          bloqueado: !modulo.pesquisaSatisfacaoId,
          mensagemBloqueado:
            'Para responder a pesquisa é necessário fazer a avaliação.'
        })

        novoPasso += 1
      }

      if (modulo?.flex) {
        disciplinas.push({
          id: 'botao-troca-modulo',
          idModulo: modulo?.id,
          titulo: 'Troca de Módulo Flexível',
          ehModuloFlex: modulo?.flex,
          tipo: 'trocaModuloFlex',
          bloqueado: !modulo?.podeRealizarTrocaModulo,
          mensagemBloqueado:
            'Troca de Módulo Flexível desabilitada. Um ou mais requisitos não foram atendidos'
        })
      }

      const mensagemBloqueioModulo = modulo.bloqueadoPesquisaSatisfacaoPendente
        ? 'É preciso realizar a pesquisa de satisfação dos módulos anteriores para liberar a próxima etapa'
        : null

      novasEtapas.push({
        id: modulo.id,
        titulo: modulo.nomeModulo,
        subItens: disciplinas,
        ehModuloFlex: modulo?.flex,
        bloqueado:
          modulo.bloqueadoPesquisaSatisfacaoPendente || bloquearAcessoAluno,
        mensagemBloqueado: mensagemBloqueioModulo,
        notificacaoModalBloqueado: modulo.bloqueadoPesquisaSatisfacaoPendente ? (
          <>
            <p>
              Você ainda não realizou a pesquisa de satisfação dos módulos
              anteriores e para liberar a próxima etapa, é necessário que
              responda as perguntas e nos avalie.
            </p>
            <p>Sua participação é muito importante para nós!</p>
          </>
        ) : null
      })
    })

    const disciplinasTCC: StepperItem[] = []
    if (
      salaAula.moduloTCC &&
      OpcaoTCC[salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Aproveitamento]
    ) {
      const dataLiberacaoModuloTCC = new Date(salaAula.moduloTCC.dataLiberacao)
      const moduloBloqueado =
        salaAula.moduloTCC.dataLiberacao && dataLiberacaoModuloTCC > new Date()

      salaAula.moduloTCC.disciplinas?.forEach(d => {
        const subItens: StepperItem[] = []

        d.materiais?.forEach((material, indice) => {
          subItens.push(
            itemStepperMaterial(
              material,
              <Material
                id={material.id}
                tipo={material.tipoMaterial}
                url={
                  material.tipoMaterial === SalaAulaTipoMaterial.Video
                    ? material.linkMaterial
                    : undefined
                }
                ultimoMaterial={indice + 1 === d.materiais.length}
                materialTCC={true}
                pdf={material.extensao === '.pdf'}
              />,
              novoPasso,
              moduloBloqueado,
              moduloBloqueado
                ? mensagemEtapaBloqueada(dataLiberacaoModuloTCC)
                : undefined,
              true
            )
          )

          if (
            (material.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
              material.extensao === '.pdf') ||
            material.tipoMaterial === SalaAulaTipoMaterial.Video ||
            material.tipoMaterial === SalaAulaTipoMaterial.HiperLink
          ) {
            novoPasso += 1
          }
        })

        if (d.avaliacaoId) {
          const dataLiberacaoAvaliacaoTCC = new Date(d.dataLiberacaoAvaliacao)
          const dataLimiteAvaliacao = new Date(d.dataLimiteAvaliacao)

          const avaliacaoBloqueadaAntesPrazo =
            d.dataLiberacaoAvaliacao && dataLiberacaoAvaliacaoTCC > new Date()

          const avaliacaoBloqueadaDepoisPrazo =
            d.dataLimiteAvaliacao && dataLimiteAvaliacao <= new Date()

          let mensagemAvaliacaoBloqueada: string

          if (moduloBloqueado) {
            mensagemAvaliacaoBloqueada = mensagemEtapaBloqueada(
              dataLiberacaoModuloTCC
            )
          }

          if (avaliacaoBloqueadaAntesPrazo) {
            mensagemAvaliacaoBloqueada = 'Avaliação ainda não está liberada'
          }

          if (avaliacaoBloqueadaDepoisPrazo) {
            mensagemAvaliacaoBloqueada =
              'Prazo limite de envio da avaliação encerrado'
          }

          if (salaAula.moduloTCC.possuiGrupoDiscussaoTCC) {
            subItens.push({
              id: v4(),
              titulo: 'Converse com o professor',
              passo: (novoPasso += 1),
              componente: (
                <GrupoDiscussaoTCC
                  orientacaoEmAndamento={salaAula.orientacaoEmAndamento}
                />
              )
            })

            novoPasso += 1
          }

          subItens.push({
            id: d.avaliacaoId,
            titulo: 'Envio do trabalho final',
            passo: novoPasso,
            icone: IconeAvaliacao,
            componente: (
              <Avaliacao
                idModulo={salaAula.moduloTCC.id}
                idDisciplina={d.id}
                tcc
                idAvaliacao={d.avaliacaoId}
              />
            ),
            bloqueado: avaliacaoBloqueadaAntesPrazo || moduloBloqueado,
            mensagemBloqueado: mensagemAvaliacaoBloqueada,
            tcc: true
          })

          novoPasso += 1
        }

        disciplinasTCC.push({
          id: d.id,
          titulo: 'Informações do TCC',
          passo: (novoPasso += 1),
          componente: (
            <OpteTCC
              salaAulaId={salaAula.id}
              opcaoTCC={salaAula.opcaoTCC}
              dataLiberacaoTCC={salaAula.dataLiberacaoOpteTCC}
              dataBloqueioTCC={salaAula.dataBloqueioSalaAula}
              idMatricula={estado.idMatricula}
              podeSolicitarTCC={salaAula.podeSolicitarTCC}
              ehReingresso={salaAula.reingresso}
              dataCriacaoSalaDeAula={salaAula.dataInicio}
              dataFimSalaDeAula={salaAula.dataFimSalaAula}
            />
          )
        })

        novoPasso += 1
        const disciplinaTCCBloqueada =
          OpcaoTCC[salaAula.opcaoTCC] === OpcaoTCC[OpcaoTCC.Dispensa] ||
          (salaAula?.moduloTCC?.dataLiberacao
            ? calcularDistanciaData(salaAula?.moduloTCC?.dataLiberacao) <= 0
            : calcularDistanciaData(salaAula.dataBloqueioSalaAula) <= 0)

        disciplinasTCC.push({
          id: d.id,
          titulo: d.nomeDisciplina,
          subItens,
          bloqueado: disciplinaTCCBloqueada
        })
      })
      novasEtapas.push({
        id: salaAula.moduloTCC.id,
        titulo: salaAula.moduloTCC.nomeModulo,
        subItens: disciplinasTCC,
        bloqueado: salaAula.periodoRecuperacao
      })
    }

    if (salaAula.materiaisComplementares) {
      const tipoMaterialComplementar: StepperItem[] = []
      const tipoMaterialComplementarArtigo = salaAula.materiaisComplementares.filter(
        mc => mc.tipoMaterialComplementar === TipoMaterialComplementar.Artigos
      )

      const disciplinaComplementarArtigo = salaAula.disciplinasComplementares?.find(
        x => x.tipo === TipoMaterialComplementar.Artigos
      )

      const tipoMaterialComplementarEncontro = salaAula.materiaisComplementares.filter(
        mc => mc.tipoMaterialComplementar === TipoMaterialComplementar.Encontros
      )

      const disciplinaComplementarEncontro = salaAula.disciplinasComplementares?.find(
        x => x.tipo === TipoMaterialComplementar.Encontros
      )

      const tipoMaterialComplementarGestaoDeCarreira = salaAula.materiaisComplementares.filter(
        mc =>
          mc.tipoMaterialComplementar ===
          TipoMaterialComplementar.GestaoDeCarreira
      )

      const disciplinaComplementarGestaoDeCarreira = salaAula.disciplinasComplementares?.find(
        x => x.tipo === TipoMaterialComplementar.GestaoDeCarreira
      )

      const subItensArtigo: StepperItem[] = []
      const subItensEncontro: StepperItem[] = []
      const subItensGestaoDeCarreira: StepperItem[] = []

      tipoMaterialComplementarArtigo.forEach((materialComplementar, indice) => {
        subItensArtigo.push(
          itemStepperMaterial(
            materialComplementar,
            <Material
              id={materialComplementar.id}
              tipo={materialComplementar.tipoMaterial}
              url={
                materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Video
                  ? materialComplementar.linkMaterial
                  : undefined
              }
              ultimoMaterial={
                indice + 1 === tipoMaterialComplementarArtigo.length
              }
              pdf={materialComplementar.extensao === '.pdf'}
              materialComplementar
            />,
            novoPasso,
            null,
            undefined,
            false,
            true
          )
        )

        if (
          (materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
            materialComplementar.extensao === '.pdf') ||
          materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Video ||
          materialComplementar.tipoMaterial === SalaAulaTipoMaterial.HiperLink
        ) {
          novoPasso += 1
        }
      })

      tipoMaterialComplementar.push({
        id: 'ipgs-academy-artigos',
        titulo: disciplinaComplementarArtigo?.nome ?? 'Artigos',
        subItens: subItensArtigo
      })

      tipoMaterialComplementarEncontro.forEach(
        (materialComplementar, indice) => {
          subItensEncontro.push(
            itemStepperMaterial(
              materialComplementar,
              <Material
                id={materialComplementar.id}
                tipo={materialComplementar.tipoMaterial}
                url={
                  materialComplementar.tipoMaterial ===
                  SalaAulaTipoMaterial.Video
                    ? materialComplementar.linkMaterial
                    : undefined
                }
                ultimoMaterial={
                  indice + 1 === tipoMaterialComplementarEncontro.length
                }
                pdf={materialComplementar.extensao === '.pdf'}
                materialComplementar
              />,
              novoPasso,
              null,
              undefined,
              false,
              true
            )
          )

          if (
            (materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
              materialComplementar.extensao === '.pdf') ||
            materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Video ||
            materialComplementar.tipoMaterial === SalaAulaTipoMaterial.HiperLink
          ) {
            novoPasso += 1
          }
        }
      )

      tipoMaterialComplementar.push({
        id: 'ipgs-academy-encontros',
        titulo: disciplinaComplementarEncontro?.nome ?? 'Aulas',
        subItens: subItensEncontro
      })

      tipoMaterialComplementarGestaoDeCarreira.forEach(
        (materialComplementar, indice) => {
          subItensGestaoDeCarreira.push(
            itemStepperMaterial(
              materialComplementar,
              <Material
                id={materialComplementar.id}
                tipo={materialComplementar.tipoMaterial}
                url={
                  materialComplementar.tipoMaterial ===
                  SalaAulaTipoMaterial.Video
                    ? materialComplementar.linkMaterial
                    : undefined
                }
                ultimoMaterial={
                  indice + 1 === tipoMaterialComplementarGestaoDeCarreira.length
                }
                pdf={materialComplementar.extensao === '.pdf'}
                materialComplementar
              />,
              novoPasso,
              null,
              undefined,
              false,
              true
            )
          )

          if (
            (materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Anexo &&
              materialComplementar.extensao === '.pdf') ||
            materialComplementar.tipoMaterial === SalaAulaTipoMaterial.Video ||
            materialComplementar.tipoMaterial === SalaAulaTipoMaterial.HiperLink
          ) {
            novoPasso += 1
          }
        }
      )

      tipoMaterialComplementar.push({
        id: 'ipgs-academy-gestao-carreira',
        titulo:
          disciplinaComplementarGestaoDeCarreira?.nome ?? 'Gestão de carreira',
        subItens: subItensGestaoDeCarreira
      })

      novasEtapas.push({
        id: 'ipgs-academy',
        titulo: 'Saúde em Pauta',
        subItens: tipoMaterialComplementar,
        bloqueado: bloquearAcessoAluno
      })
    }

    novasEtapas.push({
      id: 'grupo-discussao',
      titulo: 'Fórum de dúvidas',
      passo: novoPasso,
      componente: <GrupoDiscussao />,
      bloqueado: bloquearAcessoAluno
    })

    if (
      estado.salaAula.cursoPossuiTCC &&
      estado.salaAula.modalidadeCurso !== ModalidadeCurso.Livre &&
      estado.salaAula.modalidadeCurso !== ModalidadeCurso.Evento &&
      OpcaoTCC[estado.salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Sim] &&
      OpcaoTCC[estado.salaAula.opcaoTCC] !== OpcaoTCC[OpcaoTCC.Dispensa] &&
      !salaAula.periodoRecuperacao
    ) {
      novasEtapas.push({
        id: 'opte-tcc',
        titulo: 'TCC',
        passo: bloquearAcessoAluno ? 1 : (novoPasso += 1),
        componente: (
          <OpteTCC
            salaAulaId={salaAula.id}
            opcaoTCC={salaAula.opcaoTCC}
            dataLiberacaoTCC={salaAula.dataLiberacaoOpteTCC}
            dataBloqueioTCC={salaAula.dataBloqueioSalaAula}
            idMatricula={estado.idMatricula}
            podeSolicitarTCC={salaAula.podeSolicitarTCC}
            ehReingresso={salaAula.reingresso}
            dataCriacaoSalaDeAula={salaAula.dataInicio}
            dataFimSalaDeAula={salaAula.dataFimSalaAula}
          />
        ),
        mensagemBloqueado: `A opção de TCC será liberada na data de ${FuncoesDataHora.dataToLocaleString(
          salaAula?.dataLiberacaoOpteTCC
        )}`
      })
    }

    novoPasso += 1

    const ehPosGraduacao =
      estado.salaAula.modalidadeCurso === ModalidadeCurso.PosGraduacaoEad ||
      estado.salaAula.modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFull ||
      estado.salaAula.modalidadeCurso === ModalidadeCurso.PosGraduacaoEadFlex ||
      estado.salaAula.modalidadeCurso === ModalidadeCurso.PosGraduacaoPresencial

    if (ehPosGraduacao && estado.salaAula.turmaSoCertificacao === false) {
      novasEtapas.push({
        bloqueado: salaAula.periodoRecuperacao,
        id: 'opte-prorrogacao',
        titulo: 'Prorrogação',
        passo: novoPasso,
        componente: (
          <OpteProrrogacao
            salaAulaId={salaAula.id}
            podePedirProrrogacaoDataLimite={
              salaAula.podeSolicitarProrrogacaoDataLimite
            }
            podePedirProrrogacaoDataLiberacao={
              salaAula.podeSolicitarProrrogacaoDataLiberacao
            }
            podePedirProrrogacaoPendencias={
              salaAula.podeSolicitarProrrogacaoPendencias
            }
            possuiProrrogacao={salaAula.possuiProrrogacao}
            dataInicioSolicitacao={salaAula.dataLiberacaoPedidoProrrogacao}
            dataFimSolicitacao={salaAula.dataLimitePedidoProrrogacao}
            tempoMaximoProrrogacao={salaAula.tempoMaximoProrrogacao}
            dataFimSala={salaAula.dataFimSalaAula}
          />
        )
      })
    }

    return novasEtapas
  }, [
    estado.salaAula?.modulos,
    estado.salaAula?.materiaisTurma,
    estado.salaAula?.dataLiberacao,
    estado.salaAula.aprovado,
    estado.itensMarcados
  ])

  const definirPasso = (passo: number) => {
    definirEstado(old => ({ ...old, passo }))
  }

  const definirProgresso = (progresso: number) => {
    definirEstado(old => ({ ...old, salaAula: { ...old.salaAula, progresso } }))
  }

  const definirSalaAula = (salaAula: SalaAulaOutros) => {
    definirEstado(old => ({
      ...old,
      salaAula
    }))
  }

  const definirItensMarcados = (itensMarcados: string[]) => {
    definirEstado(old => ({ ...old, itensMarcados }))
  }

  const downloadMaterial = async (
    id: string,
    materialTCC?: boolean,
    complementar?: boolean
  ) => {
    if (materialTCC) {
      await Api.SalaAulaOutros.DownloadMaterialTCC(id, estado.salaAula.id)
    } else if (complementar) {
      await Api.SalaAulaOutros.DownloadMaterialComplementar(
        id,
        estado.salaAula.id
      )
    } else {
      await Api.SalaAulaOutros.DownloadMaterial(id, estado.salaAula.id)
    }
  }

  const atualizarRecarregarSala = (recarregarSala: boolean) => {
    definirEstado(old => ({
      ...old,
      recarregarSala: recarregarSala
    }))
  }

  const atualizarPassoAoAtualizar = (passo?: string) => {
    definirEstado(old => ({
      ...old,
      passoAoAtualizarSala: passo
    }))
  }

  const recusarInsercaoCartao = () => {
    definirEstado(old => ({
      ...old,
      fecharModal: true
    }))
  }

  return {
    ...estado,
    definirPasso,
    definirProgresso,
    definirSalaAula,
    definirItensMarcados,
    downloadMaterial,
    atualizarRecarregarSala,
    atualizarPassoAoAtualizar,
    recusarInsercaoCartao,
    etapas
  }
}

export default createContainer<
  SalaAulaOutrosContainer,
  { idMatricula: string }
>(useSalaAulaOutros)
